import React from "react";
import "../styles/payments.css";

const Payments = () => {
  return (
    <div className="payments-container">
      <div className="payment-item">
        {
          <img
            src="/assets/pagos-imagen.png"
            alt="Efectivo/Transferencia"
            id="pagos"
          />
        }
        <p id="pago">Efectivo/Transferencia</p>
      </div>

      <div className="payment-item">
        {
          <img
            src="/assets/mercado-pago.png"
            alt="Mercado Pago"
            id="merc-pago"
          />
        }
        <p id="text-mer-pago">Mercado Pago</p>
      </div>

      <div className="payment-item">
        {<img src="/assets/imagen-envios.png" alt="Envíos" id="envios" />}
        <p>Envíos en San Pedro. Bs.As</p>
      </div>
    </div>
  );
};

export default Payments;
