import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/products.css";
import { Link } from "react-router-dom";
import { useScrollContext } from "../contexts/ScrollContext";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const productsPerPage = 5;
  const pagesToShow = 4;

  const { scrollData, updateScrollData } = useScrollContext();
  const { currentPage, scrollPosition, productId } = scrollData;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/products`)
      .then((response) => {
        setProducts(response.data);
        setFilteredProducts(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (productId) {
      const productElement = document.getElementById(`product-${productId}`);
      if (productElement) {
        productElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } else {
      window.scrollTo(0, scrollPosition);
    }
  }, [scrollPosition, productId]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setFilteredProducts(
      products.filter((product) => product.name.toLowerCase().includes(term)),
    );
    updateScrollData({ currentPage: 1 });
  };

  const handlePageChange = (pageNumber) => {
    updateScrollData({ currentPage: pageNumber });
  };

  const handleProductClick = (id) => {
    updateScrollData({ scrollPosition: window.scrollY, productId: id });
  };

  const formatPrice = (price) => {
    return `$${new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)}`;
  };

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct,
  );

  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
  const visiblePages = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index,
  );

  return (
    <div className="products-container" id="seccion-productos">
      <h2>Productos</h2>
      <input
        type="text"
        className="search-bar"
        placeholder="Buscar productos..."
        onChange={handleSearch}
      />
      <div className="products-grid">
        {loading ? (
          <div className="spinner"></div>
        ) : currentProducts.length > 0 ? (
          currentProducts.map((product) => (
            <div
              key={product.id}
              id={`product-${product.id}`}
              className="product-card"
            >
              <img src={`/images/${product.image}`} alt={product.name} />
              <div className="content-prod">
                <h3>{product.name}</h3>
                <p>{formatPrice(product.price)}</p>
                <Link
                  to={`/product/${product.id}`}
                  className="btn-ver-mas"
                  onClick={() => handleProductClick(product.id)}
                >
                  Ver más
                </Link>
              </div>
            </div>
          ))
        ) : (
          <p>No se encontraron productos.</p>
        )}
      </div>

      <div className="pagination">
        {startPage > 1 && (
          <button
            className="pagination-arrow"
            onClick={() => handlePageChange(startPage - pagesToShow)}
          >
            {"<"}
          </button>
        )}
        {visiblePages.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`pagination-button ${
              currentPage === page ? "active" : ""
            }`}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages && (
          <button
            className="pagination-arrow"
            onClick={() => handlePageChange(endPage + 1)}
          >
            {">"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Products;
