import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const ScrollContext = createContext(undefined);

export const ScrollProvider = ({ children }) => {
  const [scrollData, setScrollData] = useState({
    scrollPosition: 0,
    currentPage: 1,
    productId: null,
  });

  const updateScrollData = (data) => {
    setScrollData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  return (
    <ScrollContext.Provider value={{ scrollData, updateScrollData }}>
      {children}
    </ScrollContext.Provider>
  );
};

ScrollProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useScrollContext = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScrollContext must be used within a ScrollProvider");
  }
  return context;
};
